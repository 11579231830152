import React from 'react';
import './Iris.css';
import Header from "../components/Header/Header";
import { Link } from 'react-router-dom';

function Iris() {

    return (
        <>
            <Header></Header>
            <div className='BoilerXContainer'>
                <div className='solutionsHeading'>
                    <div className='leftSolution leftsolcormap'>
                        <div className='historySolution'>
                            <Link to="/Services">Services</Link> <div className='arrowSolutions'></div> <Link to="/Iris">Iris</Link>
                        </div>
                        <div className='leftBoilerX'>
                            <h1>Iris</h1>
                            <h3>Internal Rotating Inspection System</h3>
                            <h5 className='subheadAbout'>The Internal Rotating Inspection System is an advanced NDT testing method used to detect defects in pipes and tubes in all types of industries.</h5>
                            <div className='irisscanner'></div>
                            <div className='rectangle'></div>
                        </div>
                    </div>
                    <div className='rightSolution'>

                    </div>
                </div>

                <div className='bsxSection1 corrSection1'>
                    <div className='bsx1txt'>
                        <h3>The IRIS Approach</h3>
                        <h4>Discovering pipe defects</h4>
                        <h5>
                        By using ultrasound technology, IRIS involves the insertion of a rotating probe into the interior of the pipe or tube. Using water as couplant, this probe emits ultrasound waves that penetrate the inspected material, allowing for the detection of defects such as corrosion, erosion, pitting, and wall thinning. The probe rotates as it moves along the length of the pipe, providing an inspection of the entire surface.
                        </h5>
                    </div>

                    <div className='irisinlife'></div>



                    <div className='bsx1scaff wallmap irisbkg'>

                        <div className='softwtxt wallmapping iristxt'>
                            <h3>Enhancing Reliability with IRIS Testing</h3>
                            <h5>Through IRIS inspections, Boilerscan delivers insights into the integrity of piping systems, helping clients to proactively address potential issues before they escalate into costly failures. By facilitating early defect detection, IRIS enables clients to prioritize maintenance and repair tasks, optimize asset integrity management strategies, and ensure the safe and reliable operation of critical infrastructure.</h5>
                        </div>

                    </div>


                    <div className='bsx1bottom'></div>
                </div>

                <div className='corReporting'>
                    <div className='reporttxt'>
                        <h3>Reporting</h3>
                        <h5>After conducting an Internal Rotating Inspection System (IRIS) test, our specialists analyze the results to identify potential defects. The examination ensures that every aspect of the inspected component is covered allowing us to provide accurate and detailed reports, that will help you to make informed decisions regarding maintenance and repair strategies.</h5>
                    </div>
                    <div className='irisScan'></div>
                </div>



            </div>
        </>
    );

}
export default Iris;