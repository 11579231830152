import React, { useEffect } from 'react';
import './Home.css';
import InfoCircles from "../components/InfoCircles/InfoCircles"
import InfoBar from "../components/InfoBar/InfoBar";
import Header from "../components/Header/Header";
import ImageSlider from '../components/ImageSlider/ImageSlider';
import Footer from '../components/Footer/Footer';
import {
    useHistory,
    BrowserRouter as Router,
} from "react-router-dom";

function Home() {
    const history = useHistory();

    const wowo = [
        { src: require('../components/img/scanoverview.jpeg'), alt: 'Image 1', description: 'Inspection of boiler wall using the BoilerScan X equipment' },
        { src: require('../components/img/tildeilitenboiler.jpeg'), alt: 'Image 3', description: ' BoilerScan X equipment used to measure tubes in a superheater' },
        { src: require('../components/img/boiler-scanning.gif'), alt: 'Image 4', description: 'Internal inspection of boiler bank tubes' },
        { src: require('../components/img/inspectlady.jpg'), alt: 'Image 2', description: 'Manual ultrasonic testing of tube bends' },
        { src: require('../components/img/roofscn.gif'), alt: 'Image 5', description: 'Roof scanning with BoilerScan X' }
        // Add more images with descriptions here
    ];

    const handleServicesClick = () => {
        history.push("/Services");
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <Header />
            <div className='container'>
            
                <div className='bkgimage'></div>
                <div className='imgcontent'>
                    <h5 className='tophead'>Welcome to the</h5>
                    <h1> tube scanning experts</h1>
                    <h5 className='subhead'>Discover our industry-leading solutions, where our commitment to excellence is reflected in delivering optimal scanning for every case. Elevate your performance with our tailored solutions designed to meet the needs of all industries.</h5>
                </div>

                <div className='whyus'>
                    <InfoBar />
                </div>

                <div className='clientdiv'>
                    <div className='insideClient'>
                        <h3>What to expect becoming our client</h3>
                        <h5>We aim for long-term relationship and like to work close with our clients to build mutual trust. By direct personal contact thru the entire case with same expert you get a personal contact. The same expert will also be the one onsite performing inspection and deliver final report to you. Our client login page is live updated during ongoing inspections so our clients can always be up to date with our progress. There you can also find all your previous inspection reports. This way we ensure our clients quick response and outstanding service.</h5>
                        <button className='btn' onClick={() => history.push("/Contact")}>
                            <span>Contact us</span>
                        </button>
                    </div>
                </div>

                <InfoCircles />

                <h2 className='weinspect'>We inspect all types of industries and objects</h2>
                <ImageSlider images={wowo} />
                <button id='servicesscroll' className='btn' onClick={() => history.push("/Services")}>
                    <span>Our Services</span>
                </button>

                <div className='boilerservice'>
                    <h5 className='pp'>We are proud partners of <br></br>Boiler Service Safe Control</h5>
                    <div className='verticalline'></div>
                    <div className='boilerserviceimg'></div>
                    <h5 className='fi'>For more information, visit: <br></br> <a href='http://www.boilerservice.se'>www.boilerservice.se</a></h5>
                </div>

                <div className='instaSection'>
                    <h2>Recent posts</h2>
                    <iframe
                        src="//lightwidget.com/widgets/b0f106fc53915af5b1e3c6bbb56208ea.html"
                        scrolling="no"
                        allowtransparency="true"
                        className="lightwidget-widget"
                        style={{ width: '45vw', border: '0', overflow: 'hidden' }}
                    ></iframe>
                </div>

            </div>
            <Footer></Footer>
        </>
    );
}

export default Home;
