import React from 'react';
import './NearDrum.css';
import Header from "../components/Header/Header";
import { Link } from 'react-router-dom';

function NearDrum() {

    return (
        <>
            <Header></Header>
            <div className='BoilerXContainer'>
                <div className='solutionsHeading'>
                    <div className='leftSolution'>
                        <div className='historySolution'>
                            <Link to="/Services">Services</Link> <div className='arrowSolutions'></div> <Link to="/NearDrum">NearDrum</Link>
                        </div>
                        <div className='leftBoilerX'>
                            <h1>Near Drum</h1>
                            <h3>Near drum inspection</h3>
                            <h5 className='subheadAbout'>The Near Drum inspection technology enables focused inspections close to the drum wall of tubes in either the mud drum or steam drum, ensuring thorough evaluations.</h5>
                            <div className='neardrumscanner'></div>
                            <div className='rectangle'></div>
                        </div>
                    </div>
                    <div className='rightSolution'>
                       
                    </div>
                </div>

                <div className='bsxSection1'>
                    <div className='bsx1txt neardrumadjust'>
                        <h3>The Near Drum Scanner </h3>
                        <h5>
                            The near drum scanner is based on the Internal Rotary Inspection System (IRIS) technique, offers continuous rotation capability. It features motors, encoders, and electronics housed in a second-level sealed enclosure. Utilizing the "Portable Immersion" technique, it operates effectively in any orientation without the need for tube flooding.
                        </h5>
                    </div>

                    <div className='neardrumscan'></div>



                    <div className='bsx1scaff software'>

                        <div className='softwtxt'>
                            <h3>Software</h3>
                            <h5>The near drum system features automated ultrasonic scanning data acquisition and control software. It offers A-, B-, and C-scan displays along with various data analysis functions.
                                All acquired data is stored for further reference and analysis.</h5>
                        </div>

                        <div className='softwimg'></div>



                    </div>


                    <div className='bsx1bottom'></div>
                </div>

                <div className='bsxSection2'>
                    <h3 className='reportingtext'>Reporting</h3>

                    <div className='setups'>

                        <div className='setup report'>
                            <div className='reporting'>
                                <h4>Tube sheet map </h4>
                                <h5>all tubes with minimum value at each tube</h5>

                                <div className='reportimg1'></div>
                            </div>
                            <div className='reporting'>

                            <h4>Single tube report</h4>
                                <h5>all values and color view 1 tube</h5>

                                <div className='reportimg2'></div>
                            </div>

                        </div>






                    </div>


                </div>



            </div>
        </>
    );

}
export default NearDrum;