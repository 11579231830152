import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Services from "./Services/Services";
import About from "./About/About";
import Client from "./ClientLogin/ClientLogin";
import BoilerScanX from "./BoilerScanX/BoilerScanX";
import PhasedArray from "./PhasedArray/PhasedArray";
import NearDrum from "./NearDrum/NearDrum";
import CorrosionMapping from "./CorMap/CorMap";
import Iris from "./Iris/Iris";
import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import history from './History';

function App() {
  useEffect(() => {
    document.title = "BoilerScan Sweden AB";
  }, [])

  return (
    <>
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/about" exact component={About} />
            <Route path="/clientlogin" exact component={Client} />
            <Route path="/services" exact component={Services} />

            <Route path="/boilerScanX" exact component={BoilerScanX} />
            <Route path="/PhasedArray" exact component={PhasedArray} />
            <Route path="/NearDrum" exact component={NearDrum} />
            <Route path="/CorMap" exact component={CorrosionMapping} />
            <Route path="/Iris" exact component={Iris} />
          </Switch>
        </Router>
      </div>
    </>
  );
}

export default App;

