import React from 'react';
import './BoilerScanX.css';
import Header from "../components/Header/Header";
import { Link } from 'react-router-dom';
import ScrollList from '../components/ScrollList/ScrollList';

function BoilerScanX() {

    return (
        <>
            <Header></Header>
            <div className='BoilerXContainer'>
                <div className='solutionsHeading'>
                    <div className='leftSolution'>
                        <div className='historySolution'>
                            <Link to="/Services">Services</Link> <div className='arrowSolutions'></div> <Link to="/BoilerScanX">BoilerScan X</Link>
                        </div>
                        <div className='leftBoilerX'>
                            <h1>BoilerScan X</h1>
                            <h3>The ultimate scanning solution</h3>
                            <h5 className='subheadAbout'>The BoilerScan X system is built with an X-over technique based on magnetism, eddy current and
                                ultrasoundmakes which makes it the superior choice for tube scanning.</h5>
                            <div className='boilerXscanner'></div>
                        </div>
                    </div>
                    <div className='rightSolution'>
                        <div className='scrollbkg'></div>
                        <ScrollList></ScrollList>
                    </div>
                </div>

                <div className='bsxSection1'>
                    <div className='bsx1txt'>
                        <h3>Pulled by winch or by hand </h3> 
                        <h5>
                            Wall climber can be used but it slows inspection speed and produces electrical noise. Since the scanner still needs to have a safety attachment a rope or winch is preferred.
                        </h5>
                    </div>
                    <div className='bsx1img'>
                    </div>
                    <div className='bsx1scaff'>
                        <h3>Inspection can be performed without scaffolding</h3>
                        <div className='scaffsections'>
                            <div className='scaffsection'>
                                <h4>From Lift</h4>
                                <div className='scaffimg1'></div>
                                <div className='scafftxt'>
                                    <h5>Usually entire boiler width and can be run up/down and along side walls</h5>
                                </div>
                            </div>
                            <div className='scaffsection'>
                                <h4>From Safety Roof</h4>
                                <div className='scaffimg2'></div>
                                <div className='scafftxt'>
                                    <h5>Small opening 150mm wide for scanner to pass, still safe</h5>
                                </div>
                            </div>
                            <div className='scaffsection'>
                                <h4>With a top level wire setup</h4>
                                <div className='scaffimg3'></div>
                                <div className='scafftxt'>
                                    <h5>A wire put across boiler width to hang winch</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bsx1bottom'></div>
                </div>
                <div className='bsxSection2'>
                    <h3>The system can be set up in different ways to meet each project specification and condition</h3>
                    <div className='setups'>
                        <div className='setup'>
                            <div className='setupleft'>
                                <ul className='setuplist'>
                                    <li style={{ fontWeight: 'bold' }}>2 channel setup</li>
                                    <li>- Medium scanning speed</li>
                                    <li>- Inspects 50mm width/scan</li>
                                </ul>
                                <div className='setupimg1'></div>
                            </div>
                            <div className='setupright'>
                                <div className='setupimg2'></div>
                                <ul className='setuplist'>
                                    <li style={{ fontWeight: 'bold' }}>3 channel setup</li>
                                    <li>- Low scanning speed</li>
                                    <li>- Inspects 75mm width/scan</li>
                                    <li>- Sensitive to external noise</li>
                                </ul>
                            </div>
                        </div>

                        <div className='setup'>
                            <div className='setupleft'>
                                <ul className='setuplist'>
                                    <li style={{ fontWeight: 'bold' }}>1 channel setup</li>
                                    <li>- Fast scanning speed</li>
                                    <li>- Inspects 25mm width/scan</li>
                                    <li>- Tolerates very rough surface</li>
                                </ul>
                                <div className='setupimg3'></div>
                            </div>
                            <div className='setupright'>
                                <div className='setupimg4'></div>
                                <ul className='setuplist'>
                                    <li style={{ fontWeight: 'bold' }}>3x1 channel setup</li>
                                    <li>- Very fast scanning speed with 3 tubes/scan </li>
                                    <li>- Covers 25mm width on each tube</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bsxSection3'>
                    <div className='limittxt'>
                        <h3>Limitations</h3>
                        <h5>Scanning can be done on overlay welded-, metal sprayed or compound tube but tubes must be magnetic. Welding in proximity to scanning equipment or on the same wall can be a source of noise
                            <br></br>
                            <br></br>
                            How the scaffold is built highly affects scanning speed. Scaffold minimum 150mm from tube surface and no longer than 500mm from wall. Scanning can be done on rough surface, but it affect scanning speed
                            <br></br>
                            <br></br>
                            Huge amount of data points is stored and can be used for future comparison and exact calculation of corrosion speed.
                        </h5>
                    </div>
                    <div className='limitimgs'>
                        <h4>To scan 100% scaffolding is still needed at all ”obstacles” like:</h4>
                        <div className='limitalign'>
                            <div className='limitimg1'>
                                <div className='limitdsc'><h5>Old lifting lugs</h5></div>
                            </div>
                            <div className='limitimg2'>
                                <div className='limitdsc'><h5>Bad cleaning</h5></div>
                            </div>
                            <div className='limitimg3'>
                                <div className='limitdsc'><h5>High weld caps</h5></div>
                            </div>
                            <div className='limitimg4'>
                                <div className='limitdsc'><h5>Tube bends and concrete areas</h5></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default BoilerScanX;