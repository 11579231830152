import React, { useState, useEffect } from 'react';
import './ScrollList.css';
import starImage from './small-star.png'; // Import the image using require

const ScrollList = () => {
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('scroll-container');
            const scrollTop = container.scrollTop;
            const containerHeight = container.clientHeight;
            const centerOffset = containerHeight / 2;
            const items = document.getElementsByClassName('list-item');

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const itemTop = item.offsetTop - container.offsetTop;
                const itemBottom = itemTop + item.clientHeight;

                if (itemTop < scrollTop + centerOffset && itemBottom > scrollTop + centerOffset) {
                    setActiveItem(i);
                    break;
                }
            }
        };

        const container = document.getElementById('scroll-container');
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleItemClick = (index) => {
        setActiveItem(index);
        scrollToCenter(index);
    };

    const scrollToCenter = (index) => {
        const item = document.getElementById(`item-${index}`);
        const container = document.getElementById('scroll-container');
        const containerRect = container.getBoundingClientRect();
        const itemRect = item.getBoundingClientRect();
        const offset = itemRect.top - containerRect.top - (containerRect.height - itemRect.height) / 2;
        container.scrollTop += offset;
    };

    const titles = [
        'High accuracy and repeatability',
        'Thickness data every mm',
        'Rough surface can be scanned',
        'No water or couplant',
        'No grinding needed',
        'All data saved and stored',
        'Very fast scanning, with 10-20m/min',
    ];

    const renderItems = () => {
        const items = [];
        const descriptions = [
            'Description for Item 0',
            'Description for Item 1',
            'Description for Item 2',
            'Description for Item 3',
            'Description for Item 4',
            'Description for Item 5',
            'Description for Item 6',
        ];
        for (let i = 0; i < 7; i++) {
            items.push(
                <div
                    key={i}
                    id={`item-${i}`}
                    className={`list-item ${i === activeItem ? 'active' : ''}`}
                    onClick={() => handleItemClick(i)}
                >
                    <h3>{titles[i]}</h3> {/* Render title dynamically */}
                    <img src={starImage} alt={`Item ${i}`} /> {/* Adjust size of the image */}
                    <div></div>
                    {i === activeItem && <p className="description">{descriptions[activeItem]}</p>}
                </div>
            );
        }
        return items;
    };

    return (
        <div id="scroll-container" className="scroll-list">
            {renderItems()}
        </div>
    );
};

export default ScrollList;
