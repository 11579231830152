import React from 'react';
import './Contact.css';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Tel } from './tel.svg';
import Header from "../components/Header/Header";
import Footer from '../components/Footer/Footer';
import ContactForm from './ContactForm';
import { ReactComponent as FB } from './facebook.svg';
import { ReactComponent as Insta } from './insta.svg';

function Contact() {

    return (
        <>
            <Header></Header>
            <div className='aboutContainer'>
                <div className='splitBkg'>
                    <div className='contactInfo'>
                        <h1>Get in touch</h1>
                        <h5 className='subheadAbout subcontact'>If your interested in our service, or have any questions, please don't hesitate to contact us!</h5>
                        <div className='contacticons'></div>
                        <div className='contactinformation'>
                        <div className='address'>
                            <h4>Address</h4>
                            <h5>Tegelbruksvägen 18 <br></br> 89155 Arnäsvall <br></br> Örnsköldsvik, Sweden</h5>
                        </div>
                        <a className='contactbox' href='mailto:info@boilerscan.com'>
                   
                            <h5>info@boilerscan.com</h5>
                        </a>
                        <a className='contactbox' href='tel:+46703131042'>
                        
                            <h5>+46 70-3131042</h5>
                        </a>

                        <h5 className='ya'>Avaliable for you 24/7</h5>
                        <div className='follow'>
                            <h4>Follow us</h4>

                            <a href='https://www.instagram.com/boilerscan/'><FB></FB></a>
                            <a href='https://www.instagram.com/boilerscan/'><Insta></Insta></a>
                        </div>
                        </div>


                    </div>
                    <div className='map'>
                        <div className='mappi'>
                            <ContactForm></ContactForm>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );

}
export default Contact;