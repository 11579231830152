import React, { Component } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource, XYZ } from 'ol/source';
import { Icon, Style } from 'ol/style';

import locationMarkerUrl from '../components/img/locationmap.png';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef(); // Create a ref for the map container
    this.map = null; // Initialize map instance
  }

  componentDidMount() {
    // Call the initMap function to create the map and store the map instance
    this.initMap();
  }

  initMap() {
    // Coordinates of the address
    var coordinates = fromLonLat([18.783710, 63.309429]);

    // Create a marker feature
    var marker = new Feature({
      geometry: new Point(coordinates)
    });

    // Create a marker style
    var markerStyle = new Style({
      image: new Icon({
        
        src: locationMarkerUrl, // URL of the marker icon
        scale: 0.17 // Adjust the size of the marker icon as needed
      })
    });

    marker.setStyle(markerStyle);

    // Create a vector layer with the marker feature
    var vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [marker]
      })
    });

    // Create a custom tile layer with a dark map style
    var darkLayer = new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png' // Example URL for a dark map tile server
      })
    });

    // Create the map
    this.map = new Map({
      target: this.mapRef.current, // The map container element
      layers: [
        darkLayer, // Add the custom tile layer with dark map style
        vectorLayer // Add the vector layer with the marker
      ],
      view: new View({
        center: coordinates, // Center the map on the specified coordinates
        zoom: 14 // Set the initial zoom level
      })
    });
  }

  render() {
    return (
      <div>
        {/* Map container */}
        <div ref={this.mapRef} style={{ height: '60vh', width: '85%' }}></div>
        {/* Other content of the contact form */}
      </div>
    );
  }
}

export default ContactForm;
