import React from 'react';
import './InfoBar.css';
import { ReactComponent as Stapel } from './stapel.svg';
import { ReactComponent as Linjal } from './linjal.svg';
import { ReactComponent as Bock } from './bock.svg';
import { ReactComponent as Data } from './data.svg';

const InfoBar = () => {

    return (
        <div className='infoContainer'>
           
           
            <div className='infobar'>
            
                <div className='infostars'>
                <h3>The safe and reliable choice<div className='txtBorder'></div></h3>
                    <div className='infostar'><Bock className='icon'></Bock> </div>
                    <div className='infostar'><Stapel className='icon'></Stapel></div>
                    <div className='infostar'><Data className='icon'></Data></div>
                    <div className='infostar'><Linjal className='icon'></Linjal></div>
                </div>
                <div className='infoTxt'>
                    <h2> Why choose BoilerScan?</h2>
                    <div className='infoBox'>
                        <h5>Our scanning <span className='spanblue'>eliminates the risk of missing critical defects</span> on areas not inspected when only performing spot check.</h5>
                    </div>
                    <div className='infoBox'>
                        <h5>We deliver reliable result due to the <span className='spanblue'> high repeatability technique </span> and the big amount of compared data.</h5>
                    </div>
                    <div className='infoBox'>
                        <h5>All <span className='spanblue'>data stored for future reference </span>and easily compared with previous inspections, makes scanning a supreme lifetime assessment tool.</h5>
                    </div>
                    <div className='infoBox'>
                        <h5>Our technique makes it <span className='spanblue'> easy to compare measurements </span>between years to determine, trends, defect growth rate and object remaining lifetime.</h5>
                    </div>
                </div>
                <div className='infoImages'>
                    <div className='infoimg1'></div>
                    <div className='infoimg2'></div>
                    <div className='infoimg3'></div>
                </div>
            </div>
        </div>
    );
     
}

export default InfoBar

