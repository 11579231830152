import React from 'react';
import './CorMap.css';
import Header from "../components/Header/Header";
import { Link } from 'react-router-dom';

function CorMap() {

    return (
        <>
            <Header></Header>
            <div className='BoilerXContainer'>
                <div className='solutionsHeading'>
                    <div className='leftSolution leftsolcormap'>
                        <div className='historySolution'>
                            <Link to="/Services">Services</Link> <div className='arrowSolutions'></div> <Link to="/CorMap">Corrosion Mapping</Link>
                        </div>
                        <div className='leftBoilerX'>
                            <h1>Corrosion Mapping</h1>
                            <h3>Assessing the extent and severity of corrosion</h3>
                            <h5 className='subheadAbout'>An advanced NDT method that provides insights into the condition of the inspected object. By using corrosion mapping,  we can detect and visualize corrosion-related issues within various industries.</h5>
                            <div className='cormapscanner'></div>
                            <div className='rectangle'></div>
                        </div>
                    </div>
                    <div className='rightSolution'>

                    </div>
                </div>

                <div className='bsxSection1 corrSection1'>
                    <div className='bsx1txt'>
                        <h3>Benefits of Corrosion Mapping</h3>
                        <h4>Visualize Corrosion with 100% thickness measurement</h4>
                        <h5>
                            Conventional manual ultrasonic inspection, while reliable for measuring wall thickness, lacks precise location reporting and coverage projection, leading to poor reproducibility. Wall thickness and measurementlocation data is digitally stored, improving accuracy and reproducibility.
                        </h5>
                    </div>

                    <div className='cormapact'></div>



                    <div className='bsx1scaff wallmap'>

                        <div className='softwtxt wallmapping'>
                            <h3>Wall thickness mapping</h3>
                            <h5>Corrosion mapping efficiently detects and measures corrosion, erosion, pitting, doubling, and delamination, even mapping entire wall thicknesses. BoilerScan triples scan speed and achieves 1x1 mm resolution, enhancing reproducibility for effective corrosion monitoring and efficient calculation of material corrosion rates.</h5>
                        </div>

                    </div>


                    <div className='bsx1bottom'></div>
                </div>

                <div className='corReporting'>
                    <div className='reporttxt'>
                        <h3>Reporting</h3>
                        <h5>Corrosion mapping resembles measuring wall thickness but with multiple measurements densely plotted on a colormap. Changes in thickness due to corrosion, erosion, or other factors are indicated by color shifts, typically from blue to red. All data is saved for computer analysis, providing clear, comprehensive views of wall thickness patterns and facilitating accurate, reproducible follow-up inspections over time.</h5>
                    </div>
                    <div className='threedCor'></div>
                </div>



            </div>
        </>
    );

}
export default CorMap;