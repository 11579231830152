import React from 'react';
import './ClientLogin.css';
import Header from "../components/Header/Header";






function Client() {

    return (
        <>
          <Header></Header>
        <div className='aboutContainer'>
      

            <div className='bkgimage clientbkg'> </div>

            <div className='contentclientlogin'>
                <h1>Client Login</h1>
                <h5 className='subhead'>In our client account login page you can find all inspection reports and documents regarding your case. During inspection your account will be updated daily with inspection progress and daily reports.
                    Client can have several different logins, for example 1 login per object or boiler if desired.</h5>
                <a className='btn srvcbtn' href="https://login.boilerscan.com/">
                    <span>Take me to client login</span>
                </a>

                <h5 className='italictxt'>Client Login and password are provided by BoilerScan upon request.
                    Login problem, missing documents in your account or any question contact BoilerScan by pressing <a className='redlink' href='/Contact'>here.</a></h5>
            </div>




        </div>
        </>
    );

}
export default Client;