import React from 'react';
import './Quote.css';
import { ReactComponent as Quotelogo } from './quote.svg';

const Quote = () => {

    return (
        <div className='quotecontainer'>
            <Quotelogo className='oo'></Quotelogo>
            <div className='quotetxt'>Our success is not only due to the quality of our work, it's down to attitude, our approach and the way we treat our clients.</div>
            <div className='available'>
                Avaliable for you 24/7.
            </div>
        </div>
    );

}

export default Quote
 
