import React from 'react';
import './Footer.css';

import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Plats } from './plats.svg';
import { ReactComponent as Klocka } from './klocka.svg';
import { ReactComponent as World } from './world.svg';
import { ReactComponent as FB } from './facebook.svg';
import { ReactComponent as Insta } from './insta.svg';


const Footer = () => {

    return (
 
        <div className='footercontainer'> 
        <div className='bluestripe'></div>

            <div className='footersection footersection2'>

                <div className='logoo'><Plats></Plats></div>
                <h4>Location</h4>
                <h5>Tegelbruksvägen 18, 89155 Arnäsvall, Sweden</h5>
            </div>

            <a className='footersection footersection1' href='mailto:info@boilerscan.com'>

                <div className='logoo'><Mail></Mail></div>
                <h4>Contact</h4>
                <h5>info@boilerscan.com</h5>

            </a>

            <div className='footersection footersection3'>

                <div className='logoo'><Klocka></Klocka></div>
                <h4>Working hours</h4>
                <h5>Available for you 24/7</h5>

            </div>
            <div className='footersection footersection4'>

                <div className='logoo'><World></World></div>
                <h4>Follow us</h4>
                <div className='media'>
                    <a href='https://www.instagram.com/boilerscan/'><FB></FB></a>
                    <a href='https://www.instagram.com/boilerscan/'><Insta></Insta></a>
                </div>
            </div>


        </div>



    );

}

export default Footer

