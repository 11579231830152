import React, { useState, useEffect } from 'react';
import './PhasedArray.css';
import Header from "../components/Header/Header";
import { Link } from 'react-router-dom';

function PhasedArray() {
    const [currentSection, setCurrentSection] = useState('');

    const handleMenuClick = (sectionId) => {
        setCurrentSection(sectionId);
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            const sections = ['pAsection1', 'pAsection2', 'pAsection3'];
            let found = false;

            // Check if scroll position is within any section other than landing
            sections.forEach((sectionId) => {
                const section = document.getElementById(sectionId);
                if (section && section.offsetTop <= scrollPosition + 50 && section.offsetTop + section.offsetHeight > scrollPosition + 50) {
                    setCurrentSection(sectionId);
                    found = true;
                }
            });

            // If not found, set the landing section as current section
            if (!found) {
                setCurrentSection('');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Header></Header>
            <div className="pAcontainer">
                <div className="pAmenu">
                    <div id='pAhistory' className='historySolution'>
                        <Link to="/Services">Services</Link> <div className='arrowSolutions'></div> <Link to="/PhasedArray">Phased Array</Link>
                    </div>
                    <div className={currentSection === 'pAsection1' ? 'menuItem active' : 'menuItem'} onClick={() => handleMenuClick('pAsection1')}>Bend Scanner</div>
                    <div className={currentSection === 'pAsection2' ? 'menuItem active' : 'menuItem'} onClick={() => handleMenuClick('pAsection2')}>Butt Welds</div>
                   
                </div>

                <div className='pAlanding'>
                    <div className='leftLanding'>
                        <div>
                            <h1>Phased Array</h1>
                            <h3>Phased array ultrasonic testing (PAUT)</h3>
                            <h5 className='subheadAbout'>Phased arrays are versatile tools for inspections, replacing conventional ultrasonics. They detect defects like cracks, voids, and corrosion pits, measure material thickness, identify property changes, evaluate weld and rivet quality, and inspect joints and interfaces, including adhesive detection and mapping.</h5>
                            <h5 className='browse'>Browse all phased array techniques below</h5>
                        </div>
                    </div>
                    <div className='rightLanding'>
                        <div className='solutionPAImg1'></div>

                    </div>
                </div>

                <div className="pAcontent">
                    <section id="pAsection1" className="pAsection">
                        <div className='pA1txt'>
                            <h2>Phased Array Bend Scanner</h2>
                            <h4>Inspection of tube bends</h4>
                            <h5>Through PAUT (Phased Array Ultrasonic Testing) technology, it's not only possible to cover the entire bend area in one or more scans but also to do so with remarkable efficiency and precision. Specialized scanners tailored to the specific diameter of the pipe are used by the BoilerScan engineers, ensuring optimal coverage and accuracy in detecting flaws and irregularities.
                                <br /><br />
                                One of the key advantages of PAUT is its ability to identify even the smallest pits within the bend area. This level of sensitivity is crucial for ensuring the integrity of the pipeline or structure being inspected, as pits can often be early indicators of corrosion or material degradation.
                                <br /><br />
                                The PAUT Bend Scanner can identify the minimum thickness points within every bend. By providing detailed and accurate thickness measurements, inspectors can assess the structural integrity of the component with confidence, identifying potential weak points or areas of concern before they escalate into more serious issues.
                                <br /><br />
                                In summary, PAUT revolutionizes the inspection process for bend areas, offering comprehensive coverage, precise flaw detection, and detailed thickness measurements. This technology plays a vital role in ensuring the safety, reliability, and longevity of critical infrastructure across various industries.</h5>
                        </div>

                        <div className='pA1img'></div>
                    </section>
                    <section id="pAsection2" className="pAsection">

              
                        <h2>Phased Array Inspection of Butt Welds</h2>
                        <h4>Examination of small diameter pipe welds in tight environments</h4>
                        <div className='pA2align'>
                    <div className='pA2txt'>
                        <h5>Through PAUT technology it's possible to get the conditions for ultrasonic testing tubes with goods thinner than 8 mm.
                            <br /> <br />
                            Special scanners are used that are clamped around the tube.
                            <br /> <br />
                            The scanner is only 10-12 mm high and can fit in the fence opening in tube panels.
                            <br /> <br />
                        </h5>

                        </div>
                        <div className='pA2img'></div>
                        </div>
                     
                        <h5>Circumferential butt welds, common in structures like small diameter boiler tubes, are inspected by BoilerScan experts to prevent component failure and hazards. Manual NDT inspections struggle to meet safety, precision, reliability, and data management standards.
                            <br /> <br />
                            Traditional radiography for weld inspection requires site evacuation and time-consuming film development. Phased Array Ultrasonic Testing (PAUT) offers advantages like radiation-free operation, 24/7 welding, and immediate inspection post-cooling.
                            <br /> <br />
                            PAUT of boiler tube butt welds requires careful management, correct setups, well trained and experienced operators using the correct defect acceptance standard. As with radiography PAUT delivers visual results and a permanent quality assurance (QA) record.
                            <br /> <br />
                            The inspection is fast - BoilerScan use field-proven high-performance phased array UT equipment that allows operation in an 11 mm (0.43 in) radial clearance envelope on pipes and tubes as small as Ø21.4 mm (0.84 in).
                            It's easily adaptable with diverse wedges and probes, ensuring compatibility with any tube specifications.
                            <br /> <br />
                            Dedicated probes feature laterally focused array probes with concave curvature, optimizing ultrasonic beam focus for enhanced defect resolution. Wedges include integrated irrigation channels for consistent couplant feed, with tailored curvatures to match the inspected object's diameter.
                        </h5>
                        </section>
                    
                       
                </div>
            </div>
        </>
    );
}

export default PhasedArray;
