import React from 'react';
import './InfoCircles.css';

const InfoCircles = () => {

    return (
        <div className='infocircles'>
            <div className='description2'>
                <h5 className='circleheading heading1'>We adapt to your needs</h5>
                <h5>We are always open to new challenges and take pride in finding the optimal inspection solution for each case.</h5>
            </div>
            <div className='description3 heading2'>
                <h5 className='circleheading'>Do you have a problem?</h5>
                <h5>All cases have different specifications and together we can find the optimal inspection solution for Your case.</h5>
            </div>
            <div className='description4 heading3'>
                <h5 className='circleheading'>We offer custom solutions</h5>
                <h5>Thanks to our extensive NDT experience and worldwide in service inspections we have become skilled problem solvers and developers of custom solutions.</h5>
            </div>
        </div>
    );

}

export default InfoCircles

