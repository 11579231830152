import React from 'react';
import './Services.css';
import Header from "../components/Header/Header";
import Footer from '../components/Footer/Footer';
import {
    useHistory,
    BrowserRouter as Router,
} from "react-router-dom";




function Services() {

    const history = useHistory();

    return (
        <>
            <Header></Header>
            <div className='servicesContainer'>
                <div className='bkgimage servicebkg'> </div>
                <div className='srvcontent'>
                    <h1>Inspection solutions for all types of industries</h1>
                    <h5 className='subhead'>Boiler tubes are our greatest expertise but with over 30 years and many thousand km of inspected tubes we have extensive experience and knowledge about many different objects.</h5>
                </div>

                <h3>View our inspection solutions</h3>
                <div className='srvlinks'>
                    <Router>
                        <button className='srvbtn' onClick={() => history.push("/BoilerScanX")}>
                            <div className='boilerScanXimg'></div>
                            <span>BoilerScan X</span>
                            <div className='circlebtn'><div className='arrow'></div></div>
                        </button>
                        <button className='srvbtn' onClick={() => history.push("/PhasedArray")}>
                            <div className='phasedarrayimg'></div>
                            <span>Phased Array</span>
                            <div className='circlebtn'><div className='arrow'></div></div>
                        </button>
                        <button className='srvbtn' onClick={() => history.push("/NearDrum")}>
                            <div className='neardrumimg'></div>
                            <span>Near Drum</span>
                            <div className='circlebtn'><div className='arrow'></div></div>
                        </button>
                        <button className='srvbtn' onClick={() => history.push("/Iris")}>
                            <div className='irisimg'></div>
                            <span>Iris</span>
                            <div className='circlebtn'><div className='arrow'></div></div>
                        </button>
                        <button className='srvbtn' onClick={() => history.push("/CorMap")}>
                            <div className='corMapimg'></div>
                            <span>Corrosion Mapping</span>
                            <div className='circlebtn'><div className='arrow'></div></div>
                        </button>
                  
                    </Router>
                </div>

                <div className='industriesServe'>
                    <div className='leftServe'>
                        <h3>Industries we serve</h3>
                        <ul className='industryList'>
                            <li>Pulp & Paper</li>
                            <li>Energy</li>
                            <li>Mining & Metal</li>
                            <li>Nuclear</li>
                            <li>Chemical, Oil & Gas</li>
                            <li>Real Estate & Construction</li>
                            <li>Manufacturing & Engineering</li>
                            <li>Infrastructure & Vehicles</li>
                            <li>Food & Pharmaceuticals</li>
                        </ul>
                    </div>

                    <div className='rightServe'>
                        <div className='serveImg'></div>
                        <div className='serveYallo'></div>
                        <div className='serveTxt'>Our biggest clients are Pulp & Paper and Power industry but we have a wide client base with long term relationships and repetitive yearly inspections.</div>
                    </div>
                </div>
                
                <h3>We do internal or external scanning depending on conditions</h3>
                <div className='internalExternalCont'>
                    <div className='externalScn'>
                        <div className='extImgDsc'>
                            <h5><span className='spanblue'>External scanning</span> of recovery boiler</h5>
                            <h5>using <span className='spanblue'>BoilerScan X</span></h5>
                        </div>
                    </div>
                    <div className='internalScn'>
                        <div className='intImgDsc'>
                            <h5><span className='spanblue'>Internal scanning</span> of a steam drum</h5>
                            <h5>Using <span className='spanblue'> drum-to-drum</span> inspection</h5>
                        </div>
                    </div>
                </div>

                <div className='inspectedObjects'>
                    <div className='leftObj'>
                        <h3>Example of inspected objects</h3>
                        <ul className='industryList'>
                            <li>Tubes on all boiler types, Recovery, Power, Waste, Steam, Heat ...</li>
                            <li>Super heater</li>
                            <li>Roof tubes</li>
                            <li>Eco bank</li>
                            <li>Screen</li>
                            <li>Generating banks</li>
                            <li>Heat exchangers</li>
                            <li>Air coolers</li>
                            <li>Air pre heater</li>
                            <li>Zero point measurement on new pipes</li>
                            <li>Feed- and firewater pipeline</li>
                            <li>+ many many more objects</li>
                        </ul>
                        <div className='objTxt'>Even if Your object isn't listed it's still a big chance we already have inspected a similar object.</div>
                    </div>
                    <div className='rightObj'>
                        <div className='inspectedobj'>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );

}
export default Services;