import React from 'react';
import $ from 'jquery';
import './About.css';
import Header from "../components/Header/Header";
import Footer from '../components/Footer/Footer';
import Quote from '../components/Quote/Quote';

function About() {

    $(function () {
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 20) {
                $(".scrollDown").addClass("vanish");
            } else {
                $(".scrollDown").removeClass("vanish");
            }
        });
    }); 

    return (
        <>
            <Header></Header>
            <div className='aboutContainer'>
                <div id='bouncingText' className='scrollDown'>
                    <h5>Scroll down to read more</h5>
                    <div className='scrollBottom'></div>
                </div>
                <div className='splitBkg'>
                    <div className='imgAbout'>
                        <div className='imgAboutTxt'>
                            <h1>We are more than just leaders in tube scanning technology</h1>
                        </div>
                    </div>
                    <div className='txtAbout'><h5 className='subheadAbout'>BoilerScan are based out of Sweden with clients all over the world. It was founded 2020 but although the company is newly started our expertise are well proven. With experienced personnel and state of the art equipment we are focused and dedicated to perform supreme scanning inspections.</h5></div>
                </div>

                <div className='splitWork'>
                    <div className='ourWork'>
                        <h2>Our work</h2>
                        <h5>We serve all types of industry and are dedicated to perform best possible scanning for every case. As scanning pioneers we have extensive experience from techniques such as Ultrasonic, Phased Array, SLOFEC, LFET, Remote Field, Eddy Current, EMAT. This has led to the development of our BoilerScan X system. The most complete and robust tube scanning system. With BoilerScan X we can provide extremely fast low resolution- or slower super high resolution scanning. Always custom adapted to Your case for superior result.</h5>
                    </div>
                    <div className='ourWorkImg'>
                        <div className='imgCollage'>
                        </div>
                    </div>
                </div>

                <div className='splitWork'>
                    <div className='ceoImg'>
                        <h5 className='ceoTxt'>Niklas Sandström <br></br> Senior Specialist Advanced NDT Level III</h5>
                    </div>
                    <div className='leftceo'>
                        <h2>About our CEO and founder - Niklas Sandström</h2>
                        <h5>I started working with Non Destructive Testing 1988. Since then I have worked with all types of NDT techniques such as, Ultrasonic, Eddy current, EMAT, X-Ray, dye Penetrant and Magnetic particle on all types of industry. 2005 I started Co-developing the Tscan scanning system and taking it to the market. Last 10 years I have spent most of time scanning tubes worldwide. <br></br><br></br> With market increasing and clients asking for more scanning options, I realized it was time to start a company dedicated to tube scanning. With extensive knowledge about all NDT techniques and the different inspection situations that can occur we can easily understand and solve your case.</h5>
                    </div>
                </div>

                <Quote></Quote>

            </div>
            <Footer></Footer>
        </>
    );

}
export default About;