import React, { useState } from 'react';
import './ImageSlider.css';

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
 
  return (
    
    <div className="carousel-container">
    

      <div className="carousel-wrapper">
        {/* Previous Image */}
        <img
          src={images[(currentImageIndex + images.length - 1) % images.length].src}
          alt={images[(currentImageIndex + images.length - 1) % images.length].alt}
          className="carousel-image-preview left"
        />
        {/* Current Image */}
        <img
          src={images[currentImageIndex].src}
          alt={images[currentImageIndex].alt}
          className="carousel-image"
        />
        {/* Next Image */}
        <img
          src={images[(currentImageIndex + 1) % images.length].src}
          alt={images[(currentImageIndex + 1) % images.length].alt}
          className="carousel-image-preview right"
        />
        {/* Image Description */}
        <div className="carousel-description">
          {images[currentImageIndex].description}
        </div>
      </div>
      {/* Previous Button */}
      <button onClick={goToPrevious} className="carousel-button previous">
        
      </button>
      {/* Next Button */}
      <button onClick={goToNext} className="carousel-button next">
   
      </button>
    </div>
  );
};

export default ImageSlider; 
